import React, { useState } from 'react'
//import { useNavigate } from 'react-router-dom';
import moment from 'moment'

import Lot from "../Tax-Lot.js"
import { formatNumber } from '../utility/Helper.js'
import {
  Grid,
  Header,
  Segment,
  Sidebar,
  Button,
  Placeholder,
  TextArea,
  Form,
  Divider,
  Label,
  Modal,
  Input
} from 'semantic-ui-react'

import PerfectScrollbar from 'react-perfect-scrollbar'

import { isUserAdmin } from '../utility/Auth'
import GoogleMap from './maps/GoogleMap'
import { ContainedAccordian } from './extras/ContainedAccordian.js'

import { LotBadgesPanel, LotDocumentInformationPanel, LotFooterInformationPanel, LotInformationPanel, LotMiscInformationPanel, LotOwnerInformationPanel, LotStatsPanel, CityDataRow } from './lots/LotInformationPanel.jsx'
import { PlaceholderStatSet, PlaceholderTableLine } from './extras/Misc.js'
import { max } from '../utility/MathHelper.js'

function concentrationCount(totals) {
  if (predominantType(totals) !== 'residential') return false
  const { unitCounts } = totals[0]
  const totalRes = unitCounts[1] + unitCounts[2] + unitCounts[3] + unitCounts[4]
  const totalFivePlus = unitCounts[5]

  if (totalRes > totalFivePlus) {
    return '1-4'
  } else {
    return '5+'
  }
}

function predominantType(totals) {
  const { unitCounts } = totals[0]
  const totalVacant = unitCounts[0];
  const totalRes = unitCounts[1] + unitCounts[2] + unitCounts[3] + unitCounts[4] + unitCounts[5]
  const totalCom = unitCounts[6]
  const totalMixed = unitCounts[7] + unitCounts[8]
  var values = [totalRes, totalCom, totalMixed, totalVacant]

  var highest = max(values)

  switch (highest) {
    case totalRes:
      return 'residential'
    case totalCom:
      return 'commercial'
    case totalMixed:
      return 'mixed use'
    case totalVacant:
      return 'vacant'
  }
}


const LotNarrative = ({ narrative }) => (
  <Modal.Content>
    <p>
     {narrative}
    </p>
  </Modal.Content>
)

const LotInformationSection = ({ selectedLot, lotUpdateHandlers, handleWideStreetChange }) => (
  <section>
    <LotOwnerInformationPanel lot={selectedLot} />
    <Divider />
    <LotDocumentInformationPanel lot={selectedLot} />
    <Divider />
    <LotInformationPanel lot={selectedLot} updateHandlers={lotUpdateHandlers} handleWideStreetChange={handleWideStreetChange} />
    <Divider />
    <LotFooterInformationPanel lot={selectedLot} />
  </section>
)

export const FullLotInformation = ({ isMobile = false, selectedLot, 
  combinedCompsActive, compsActive, handleCombinedComps, handleCombinedForcedComps, handleComps, 
  handleComps2, handleComps3, handleComps4, handleComps5, handleComps6,
  comps2Active, comps3Active, comps4Active, comps5Active, comps6Active,
  handleHistoricalComps, onFetchNarrative, handleNotesChange, handleWideStreetChange, lotUpdateHandlers, compsDate, handleCompsDateUpdate }) => {

  const [narrativeLoading, setNarrativeLoading] = useState(false);

  const goTo = (page) => {
    // Navigate doesnt allow to open to new tab
    // navigate(
    //   '/' + page + '/' + selectedLot.bbl
    // )
    let prefix = Lot.isPluto(selectedLot) ? 'bbl' : 'pid'
    window.open('/' + page + '/' + prefix + '/' + selectedLot.propertyId, '_blank')
  }

  return (
    <>
      <div style={{ maxHeight: '250px' }}>
        {/* {isUserAdmin() ? (
                  <>
                    <Button title='Owned' basic className={`no-border no-padding ${selectedLot.isOwned === undefined ? 'loading' : ''}`} icon={{ name: 'certificate', color: selectedLot.isOwned ? 'red' : '' }} floated='right' size='big' onClick={e => { handleAddToOwnership(e, selectedLot) }}></Button>
                    <Button title='Contract' basic className={`no-border no-padding ${selectedLot.isContract === undefined ? 'loading' : ''}`} icon={{ name: selectedLot.isContract ? 'file alternate' : 'file alternate outline', color: selectedLot.isContract ? 'blue' : '' }} floated='right' size='big' onClick={e => { handleAddToContract(e, selectedLot) }}></Button>
                    <Button title='Watch' basic className={`no-border no-padding ${selectedLot.isWatchlistActive === undefined ? 'loading' : ''}`} icon={{ name: selectedLot.isWatchlistActive ? 'bookmark' : 'bookmark outline', color: selectedLot.isWatchlistActive ? 'yellow' : '' }} floated='right' size='big' onClick={e => { handleAddToWatchlist(e, selectedLot) }}></Button>
                  </>
                ) : null} */}

        <section>
          <Header as='h3' className="clear-bottom">
            <span className="address">{Lot.fullAddress(selectedLot)}</span>
          </Header>
          {/* <p className="text-small dark-gray clear-bottom">{selectedLot.borocode}-{selectedLot.block}-{selectedLot.lot}</p> */}
          <p style={{ minHeight: '20px' }} className="text-small dark-gray clear-bottom">
            {selectedLot.propertyId} {!Lot.isPluto(selectedLot) && (selectedLot.block || selectedLot.lot) ? (<span>({selectedLot.block && `B: ${selectedLot.block} `}{selectedLot.lot && `L: ${selectedLot.lot}`})</span>) : null}
            
          </p>
          <p style={{ minHeight: '20px' }} className="text-small dark-gray">{selectedLot.neighborhood} {selectedLot.subdivision ? `(${selectedLot.subdivision})` : ''}</p>
          <Segment className='no-padding' basic textAlign='center'>
            <Input
              size='mini'
              type='date'
              value={compsDate}
              onChange={(e, { value }) => {
                handleCompsDateUpdate && handleCompsDateUpdate(value)
              }}
            />
            <div>
            <Button.Group size='mini'>
              <Button disabled={selectedLot.propertyType === 'V' || Lot.bldgarea(selectedLot) === 0} className='btn_comps' onClick={handleComps} toggle active={compsActive}>Comps</Button>
              {/* {Lot.isFA(selectedLot) ? (
                <Button onClick={handleComps2} className='btn_comps' toggle active={comps2Active}>Comps 2</Button>
              ) : null } */}
              <Button onClick={handleComps5} className='btn_comps' toggle active={comps5Active}>Comps 5</Button>
              <Button onClick={handleComps3} className='btn_comps' toggle active={comps3Active}>Comps 5.1</Button>
              <Button onClick={handleComps4} className='btn_comps' toggle active={comps4Active}>Comps 6</Button>
              <Button onClick={handleComps2} className='btn_comps' toggle active={comps2Active}>Comps 6.1</Button>
              <Button onClick={handleComps6} className='btn_comps' toggle active={comps6Active}>Comps 6.1P</Button>
            </Button.Group>{' '}
            {/* <Button.Group size='mini'>
            {Lot.isFA(selectedLot) && !isMobile ? (
                <Button disabled={selectedLot.propertyType === 'V' || Lot.bldgarea(selectedLot) === 0} className='btn_comps' onClick={handleCombinedComps} toggle active={combinedCompsActive}>Comps+</Button>
              ) : null}
              {Lot.isFA(selectedLot) && !isMobile ? (
                <Button disabled={selectedLot.propertyType === 'V' || Lot.bldgarea(selectedLot) === 0} className='btn_comps' onClick={handleCombinedForcedComps} toggle active={combinedCompsActive}>Comps++</Button>
              ) : null}
            </Button.Group>{' '} */}
            <Button.Group size='mini'>
              <Button onClick={() => goTo('fastclose')}>FastClose</Button>
              <Button onClick={() => goTo('comp-appraisals')}>Appraisals</Button>
              {/* {selectedLot && selectedLot.narrative && selectedLot.narrative.length > 0 ? <Modal
                    trigger={<Button>Narrative</Button>}
                    size='tiny'
                  >
                <LotNarrative narrative={selectedLot?.narrative} />
              </Modal>
              : <Button loading={narrativeLoading} disabled={narrativeLoading} onClick={() => {
                onFetchNarrative()
                setNarrativeLoading(true)
              }}>Narrative</Button>} */}
            </Button.Group>
            </div>
                
          </Segment>

          <LotBadgesPanel lot={selectedLot} />
          <Divider />
        </section>

        {Lot.isPluto(selectedLot) && <section>
          <LotStatsPanel lot={selectedLot} />
          <Divider />
        </section>}
      </div>

      {!isMobile ? (
        <PerfectScrollbar style={{ height: '100%' }} options={{ suppressScrollX: true }}>
          <LotInformationSection selectedLot={selectedLot}
            lotUpdateHandlers={lotUpdateHandlers} handleWideStreetChange={handleWideStreetChange}
          />
          <CityDataRow lot={selectedLot} />
        </PerfectScrollbar>
      ) : (
        <LotInformationSection selectedLot={selectedLot}
          lotUpdateHandlers={lotUpdateHandlers} handleWideStreetChange={handleWideStreetChange}
        />
      )}

      <section>
        <ContainedAccordian>
          <Grid className="lot-map" textAlign='left' columns={1}>
            <Grid.Row>
              <Grid.Column stretched={true}>
                <Form>
                  <TextArea key={selectedLot.lotNotes} onBlur={e => { handleNotesChange(e, selectedLot) }} placeholder='Notes...' style={{ minHeight: 50, maxHeight: 50 }} defaultValue={selectedLot.lotNotes} />
                </Form>
                <Divider hidden={true} />
                <GoogleMap
                  //compsPanelVisible={compsVisible}
                  latitude={selectedLot.latitude}
                  longitude={selectedLot.longitude}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </ContainedAccordian>
      </section>
    </>
  )
}

const HorizontalSidebar = ({ compsActive, combinedCompsActive, comps2Active, comps3Active, comps4Active, comps5Active, comps6Active,
   handleCombinedComps, handleCombinedForcedComps, handleComps, handleHistoricalComps, onFetchNarrative,
   handleComps2, handleComps3, handleComps4, handleComps5, handleComps6,
   handleFetchDocumentConnections, handleAddToWatchlist, handleAddToOwnership, handleAddToContract, handlePanelOpen, handlePanelClose,
  handleAnimationChange, handleNotesChange, handleWideStreetChange, animation, direction, visible, width, selectedLot, lotUpdateHandlers, compsDate, handleCompsDateUpdate }) => {
  //const navigate = useNavigate();

  return (
    <Sidebar
      className='side-panel-container'
      as={Segment}
      animation={animation}
      direction={direction}
      width={width}
      visible={visible}
      onVisible={handlePanelOpen}
    >
      <Grid className='side-panel-grid' style={{ height: '100%' }}>
        <Label className='panel-closer left' basic icon={`long arrow alternate ${visible ? 'right' : 'left'}`} floating onClick={handleAnimationChange}></Label>
        <Grid.Row columns={1} style={{ height: '100%' }}>
          <Grid.Column stretched style={{ height: '100%' }}>
            {selectedLot ? (
              <FullLotInformation
                selectedLot={selectedLot}
                compsActive={compsActive}
                combinedCompsActive={combinedCompsActive}
                comps2Active={comps2Active}
                comps3Active={comps3Active}
                comps4Active={comps4Active}
                comps5Active={comps5Active}
                comps6Active={comps6Active}
                handleCombinedComps={handleCombinedComps}
                handleCombinedForcedComps={handleCombinedForcedComps}
                handleComps={handleComps}
                handleHistoricalComps={handleHistoricalComps}
                handleComps2={handleComps2}
                handleComps3={handleComps3}
                handleComps4={handleComps4}
                handleComps5={handleComps5}
                handleComps6={handleComps6}
                handleNotesChange={handleNotesChange}
                handleWideStreetChange={handleWideStreetChange}
                lotUpdateHandlers={lotUpdateHandlers}
                compsDate={compsDate}
                handleCompsDateUpdate={handleCompsDateUpdate}
                onFetchNarrative={onFetchNarrative}
              />
            ) : (
              <>
                <section>
                  <Placeholder>
                    <Header as='h3' className="clear-bottom">
                      <Placeholder.Line length="full" />
                    </Header>
                    <Placeholder.Line length="long" />
                    <Placeholder.Line length="medium" />
                  </Placeholder>
                  <Divider style={{ marginTop: "1.5rem" }} />
                </section>

                <section>
                  <div className='stats-group'>
                    <div>
                      {Array.from(["DOB", "Complaints", "OATH", "HPD"], (e, i) => {
                        return <PlaceholderStatSet key={i} title={e} />
                      })}
                    </div>
                  </div>
                  <Divider />
                </section>

                <div>
                  <div>
                    <section>

                      <Grid className="lot-details" columns={2}>

                        {Array.from(Array(14), (e, i) => {
                          return <PlaceholderTableLine key={i} length={i} />
                        })}

                        <Divider />

                        {Array.from(Array(2), (e, i) => {
                          return <PlaceholderTableLine key={i} length={i} />
                        })}

                        <Divider />

                        {Array.from(Array(2), (e, i) => {
                          return <PlaceholderTableLine key={i} length={i} />
                        })}

                        <Divider />

                        {Array.from(Array(6), (e, i) => {
                          return <PlaceholderTableLine key={i} length={i} />
                        })}

                        <Divider />

                        {/* <Grid.Row>
                    <Grid.Column width={16}>
                    <Placeholder fluid>
                        <Placeholder.Image rectangular />
                    </Placeholder>
                    </Grid.Column>
                  </Grid.Row> */}

                      </Grid>

                    </section>
                    <section>
                      <Grid className="lot-notes" textAlign='left' columns={1}>
                        <Grid.Row>
                          <Grid.Column stretched={true}>
                            <Placeholder>
                              <Placeholder.Paragraph>
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                              </Placeholder.Paragraph>
                            </Placeholder>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </section>
                  </div>
                </div>
              </>
            )
            }
          </Grid.Column >
        </Grid.Row >
      </Grid >
    </Sidebar >
  )
}

export default HorizontalSidebar;